import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { getLtiParams } from './shared/store/selectors/ltiparams.selector';
import { Observable, Subject, take, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { LtiParamsState } from './shared/store/state/ltiparams.state';
import { UPUtilityService, ltiParams } from 'up';
import { AuthorizedUser } from 'up/lib/interfaces/access.interface';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements OnDestroy {
  private destroy$ = new Subject<boolean>();
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  constructor(
    private router: Router,
    private store: Store<LtiParamsState>,
    private upUtilityService: UPUtilityService,
    private translate: TranslateService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    let role!: string,
      ltiaAction!: string,
      isAuthorized: boolean = false;
    const userAuthorizedData: AuthorizedUser = route?.data;
    this.ltiParams$
      .pipe(takeUntil(this.destroy$), take(1))
      .subscribe((ltiParams: ltiParams) => {
        role = ltiParams.role.toLowerCase();
        ltiaAction = ltiParams.ltiaAction.toLowerCase();
        isAuthorized = this.upUtilityService.hasAuthorizedAccess(
          userAuthorizedData,
          role,
          ltiaAction
        );
        if (!isAuthorized) {
          this.router.navigate(['/unauthorized'], {
            state: {
              deleteUrl: `${environment.apiUrl}/ebook`,
              pageTitle:
                this.translate.instant('GENERIC.PAGE_TITLE') +
                this.translate.instant('GENERIC.UNAUTHORIZED.PAGE_TITLE'),
              isIframeLaunch: ltiaAction === 'select' ? true : false,
            },
          });
        }
      });
    return isAuthorized;
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
