import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { environment } from 'projects/ebook/src/environments/environment';
import { Subject, takeUntil } from 'rxjs';
import { UPUtilityService } from 'up';
import { Router } from '@angular/router';
@Component({
  selector: 'ebook-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnDestroy {
  logo: string = '';
  private destroy$ = new Subject<boolean>();

  constructor(
    private upUtilityService: UPUtilityService,
    private router: Router
  ) {}

  onExit() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/ebook`);
  }

  onClickAssignButton() {
    this.upUtilityService
      .createAssignment(`${environment.apiUrl}/ebook/assignment/create`)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.router.navigateByUrl('/instructor/assignstatus', {
            state: {
              responseType: 'success',
            },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.router.navigateByUrl('/instructor/assignstatus', {
            state: {
              errorDetails: error.error,
              responseType: 'error',
            },
          });
        },
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
