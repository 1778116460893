import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { ApiErrorConfig, UPUtilityService } from 'up';

@Component({
  selector: 'ebook-assign-confirmation',
  templateUrl: './assign-confirmation.component.html',
  styleUrls: ['./assign-confirmation.component.scss'],
})
export class AssignConfirmationComponent {
  routeParams: any = {};
  responseType: string;
  apiErrorConfig: ApiErrorConfig = {
    heading: 'INSTRUCTOR.ASSIGNMENT_CONFIRMATION.ERROR_HEADING',
    message: 'INSTRUCTOR.ASSIGNMENT_CONFIRMATION.ERROR_MESSAGE',
    buttonText: 'GENERIC.EXIT_BUTTON',
    moreDetails: '',
  };

  constructor(
    private upUtilityService: UPUtilityService,
    private router: Router
  ) {
    this.routeParams = this.router.getCurrentNavigation()?.extras.state;
    this.apiErrorConfig.moreDetails = this.routeParams?.['errorDetails'];
    this.responseType = this.routeParams?.['responseType'];
  }

  onExitClick() {
    this.upUtilityService.returnToCourse(`${environment.apiUrl}/ebook`);
  }
}
