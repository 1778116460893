import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil, timer } from 'rxjs';
import { ltiParams, LtiParamsState, Product, UPUtilityService } from 'up';
import { getLtiParams } from '../../../shared/store/selectors/ltiparams.selector';
import { Router } from '@angular/router';
import { ProductLaunch } from 'up';

@Component({
  selector: 'ebook-product-launch',
  templateUrl: './product-launch.component.html',
  styleUrls: ['./product-launch.component.scss'],
})
export class ProductLaunchComponent implements OnInit, OnDestroy {
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ltiParams!: ltiParams;
  product!: Product;
  productLaunch: ProductLaunch = {
    launchAppParam: 'MHEBOOK',
    routePath: '/instructor',
    routePathWarning: '/instructor/warning',
    productWarningParam: {
      state: {
        platform: 'MH Ebook',
      },
    },
  };
  isProductLoaded: boolean = false;

  private destroy$ = new Subject<boolean>();

  constructor(
    private store: Store<LtiParamsState>,
    private router: Router,
    private upUtilityService: UPUtilityService
  ) {}

  ngOnInit(): void {
    timer(200)
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.launchAppOrLoadProduct();
     });
  }

  launchAppOrLoadProduct(): void {
    let appLaunchedInNewTab = this.upUtilityService.launchAppInNewTab(
      this.productLaunch.launchAppParam
    );
    if (appLaunchedInNewTab) {
      this.navigateToAppInNewTab(appLaunchedInNewTab, this.productLaunch);
    } else {
      this.getLtiParams();
    }
  }

  getLtiParams() {
    this.ltiParams$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ltiParams: ltiParams) => {
        this.ltiParams = ltiParams;
        this.loadPairedProduct();
      });
  }

  loadPairedProduct() {
    this.product = {
      isbn: this.ltiParams?.isbn!,
      author: this.ltiParams?.productAuthor!,
      edition: this.ltiParams?.productEdition!,
      title: this.ltiParams?.productTitle!,
    };
    this.isProductLoaded = true;
  }

  launchApp(): void {
    let appLaunchedInNewTab = this.upUtilityService.launchAppInNewTab(
      this.productLaunch.launchAppParam
    );
    if (appLaunchedInNewTab) {
      this.navigateToAppInNewTab(appLaunchedInNewTab, this.productLaunch);
    }
  }

  navigateToAppInNewTab(
    appLaunchedInNewTab: any,
    productLaunch: ProductLaunch
  ): void {
    appLaunchedInNewTab.location.href =
      location.origin + productLaunch.routePath;
    this.router.navigate(
      [productLaunch.routePathWarning],
      productLaunch.productWarningParam
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
