<up-spinner></up-spinner>
<div class="container-wrapper">
  <div id="header-wrapper" *ngIf="isHeaderDisplay">
    <ebook-header></ebook-header>
    <hr aria-hidden="true" class="mt-2 mb-0" />
  </div>
  <main class="container-fluid content">
    <div class="row justify-content-center">
      <div class="col-md-12 col-lg-12 col-xl-12">
        <up-bs-toast></up-bs-toast>
        <router-outlet></router-outlet>
      </div>
    </div>
  </main>
  <div id="footer-wrapper">
    <up-footer *ngIf="isFooterDisplay"></up-footer>
  </div>
</div>
