import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  APP_INITIALIZER,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpBackend,
} from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuidLibModule } from '@mhe/quid';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { environment } from '../environments/environment';
import { UPModule, ConfigService, launchConfigs, ltiParams } from 'up';
import { LoaderInterceptor } from './shared/interceptors/loader.interceptor';
import { InterceptorErrorComponent } from './shared/interceptors/interceptor-error/interceptor-error.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { Store, StoreModule } from '@ngrx/store';
import { appReducers } from './shared/store/reducers/app.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SetLtiParams } from './shared/store/actions/ltiparams.action';
import { CommonModule } from '@angular/common';
import { InstructorModule } from './instructor/instructor.module';
import { StudentModule } from './student/student.module';
import { ProductLaunchComponent } from './instructor/components/product-launch/product-launch.component';
import { AssignConfirmationComponent } from './instructor/components/assign-confirmation/assign-confirmation.component';

export function createTranslateLoader(httpBackend: HttpBackend) {
  return new MultiTranslateHttpLoader(httpBackend, [
    './assets/quid-i18n/',
    './assets/i18n/',
    './assets/up-i18n/',
  ]);
}

export function initializeApplication(config: ConfigService, store: Store) {
  return (): Promise<void> => {
    const launchConfigs: launchConfigs = {
      createTokenApiUrl: `${environment.apiUrl}/up/jwt/token/create`,
      paramsApiUrl: `${environment.apiUrl}/ebook/params`,
      redirectUrl: '', //'https://www.mheducation.com/highered', TBD
      environmentName: environment.envName,
    };
    return config.processRequest(launchConfigs).then(
      (ltiResponse: ltiParams) => {
        const ltiParams = {
          ltiParams: ltiResponse,
        };
        store.dispatch(new SetLtiParams(ltiParams));
      },
      () => {}
    );
  };
}

@NgModule({
  declarations: [
    AppComponent,
    InterceptorErrorComponent,
    HeaderComponent,
    ProductLaunchComponent,
    ErrorComponent,
    AssignConfirmationComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    InstructorModule,
    StudentModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend],
      },
    }),
    NgbModule,
    QuidLibModule,
    UPModule,
    StoreModule.forRoot(appReducers),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    { provide: Window, useValue: window },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApplication,
      deps: [ConfigService, Store],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
