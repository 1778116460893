<header>
  <div class="d-flex justify-content-between">
    <div class="d-flex">
      <img [src]="logo" [alt]="'GENERIC.LOGO' | translate" class="ebook-logo" />
    </div>
    <div class="d-flex p-1">
      <div class="col d-flex p-1">
        <button class="btn btn-primary btn-sm" (click)="onClickAssignButton()">
          {{ "GENERIC.BUTTONS.ASSIGN" | translate }}
        </button>
      </div>
      <div class="col p-1">
        <button class="btn btn-outline-primary btn-sm">
          {{ "GENERIC.BUTTONS.UNPAIR" | translate }}
        </button>
      </div>
      <div class="col p-1">
        <button class="btn btn-outline-primary btn-sm" (click)="onExit()">
          {{ "GENERIC.BUTTONS.EXIT" | translate }}
        </button>
      </div>
    </div>
  </div>
</header>
