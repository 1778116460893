import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom, Observable } from 'rxjs';
import {
  EbookReaderService,
  IframeMetadata,
  ltiParams,
  UPUtilityService,
} from 'up';
import { environment } from '../../../../environments/environment';
import { getLtiParams } from '../../../shared/store/selectors/ltiparams.selector';
import { LtiParamsState } from '../../../shared/store/state/ltiparams.state';

@Component({
  selector: 'ebook-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
})
export class HomeComponent implements OnInit {
  ltiParams$: Observable<ltiParams> = this.store.select(getLtiParams);
  ebookReaderParams: any;
  ltiForm: string = '';
  iframeMetadata: IframeMetadata = {
    dataAutomationId: 'ebook-iframe',
    ariaLabel: this.translate.instant('GENERIC.EBOOK_ARIA_LABEL'),
    srcType: 'srcdoc',
  };

  constructor(
    private ebookReaderService: EbookReaderService,
    private store: Store<LtiParamsState>,
    private upUtilityService: UPUtilityService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.launchEbook();
  }

  async launchEbook() {
    try {
      await firstValueFrom(this.ltiParams$);
      let ltiInfo = await firstValueFrom(
        this.ebookReaderService.getEbookReaderInfoWithoutEpubCfi(
          `${environment.apiUrl}/ebook/reader/sso`
        )
      );
      if (ltiInfo) {
        this.ltiForm = this.upUtilityService.LtiFormSubmit(
          ltiInfo.lti_api_endpoint,
          ltiInfo.lti_signed_data
        );
      }
    } catch (error) {
      this.upUtilityService.navigateToErrorPage(
        'INSTRUCTOR.EBOOK.ERROR_MESSAGE'
      );
    }
  }
}
