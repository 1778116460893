import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InterceptorErrorComponent } from './interceptor-error/interceptor-error.component';
import { ToastsService, LoaderService, UPUtilityService } from 'up';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(
    private loaderService: LoaderService,
    private modalService: NgbModal,
    private toastsService: ToastsService,
    private upUtilityService: UPUtilityService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let JWTToken;

    if (
      request.url.includes('assets/i18n') ||
      request.url.includes('assets/quid-i18n')
    ) {
      return next.handle(request);
    }

    try {
      this.toastsService.clear();
      if (this.upUtilityService.getItemFromSessionStorage('JWT') != '') {
        JWTToken = this.upUtilityService.getItemFromSessionStorage('JWT');
      }
    } catch (e) {}

    let headerObj = {
      'Content-Type': 'application/json',
      authorization: 'Bearer ' + JWTToken,
      'Access-Control-Allow-Origin': '*',
    };

    request = request.clone({
      setHeaders: headerObj,
      url: request.url,
    });
    if (!request.url.includes('ebook/ai/config')) {
      this.loaderService.show();
    }

    return next.handle(request).pipe(
      tap((res) => {
        if (res instanceof HttpResponse) {
          if (res.headers.get('authorization'))
            sessionStorage.setItem(
              'JWT',
              res.headers.get('authorization')?.split(' ')[1] || ''
            );
        }
      }),
      catchError((error: HttpErrorResponse) => {
        let tokenErrorHeader = '';
        let tokenErrorBody = '';
        let showErrorModal = false;
        this.modalService.dismissAll();
        let tokenError: boolean = false;
        switch (error.error.errorCode) {
          case 'TOKEN_INVALID':
            tokenErrorHeader = 'GENERIC.MODAL.INVALID_TOKEN_HEADER';
            tokenErrorBody = 'GENERIC.MODAL.INVALID_TOKEN_MSG';
            showErrorModal = true;
            tokenError = true;
            break;
          case 'TOKEN_EXPIRED':
            tokenErrorHeader = 'GENERIC.MODAL.EXPIRED_TOKEN_HEADER';
            tokenErrorBody = 'GENERIC.MODAL.EXPIRED_TOKEN_MSG';
            showErrorModal = true;
            tokenError = true;
            break;
        }

        if (!this.upUtilityService.getTokenError() && showErrorModal) {
          this.upUtilityService.setTokenError();
          let modalRef = this.modalService.open(InterceptorErrorComponent, {
            size: 'md',
            keyboard: false,
            backdrop: 'static',
            centered: true,
          });
          modalRef.componentInstance.modalTitle = tokenErrorHeader;
          modalRef.componentInstance.modalBody = tokenErrorBody;
        }

        return throwError(error);
      }),
      finalize(() => {
        this.loaderService.hide();
      })
    );
  }
}
