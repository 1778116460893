import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AuthGuard } from '../auth.guard';
import { ProductLaunchComponent } from './components/product-launch/product-launch.component';
import { AssignConfirmationComponent } from './components/assign-confirmation/assign-confirmation.component';
import { LmsWarningComponent } from 'up';

const routes: Routes = [
  { path: '', redirectTo: '/instructor/home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'warning',
    component: LmsWarningComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assignstatus',
    component: AssignConfirmationComponent,
  },
  {
    path: 'launch',
    component: ProductLaunchComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InstructorRoutingModule {}
