import { Action } from '@ngrx/store';
import { LtiParamsState } from '../state/ltiparams.state';

export enum LtiStoreActions {
  SetLtiParams = '[LtiActions] Set Params',
  AddModuleTitle = '[LtiActions] Add Module Title',
}

export class SetLtiParams implements Action {
  public readonly type = LtiStoreActions.SetLtiParams;
  constructor(public payload: LtiParamsState) {}
}

export class AddModuleTitle implements Action {
  public readonly type = LtiStoreActions.AddModuleTitle;
  constructor(public payload: string) {}
}

export type LtiActionsUnion = SetLtiParams | AddModuleTitle;
