import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InstructorRoutingModule } from './instructor-routing.module';
import { HomeComponent } from './components/home/home.component';
import { UPModule } from 'up';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [HomeComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, InstructorRoutingModule, UPModule, TranslateModule],
  exports: [HomeComponent],
})
export class InstructorModule {}
